var josh = {
  modules : [],
  init : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('init')){
        item.init(item);
      }
    });
  },
  ready : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('ready')){
        item.ready(item);
      }
    });
  },
  scroll : function(){
    var self = this;
      self.modules.forEach(function(item, i){
        if(item.hasOwnProperty('scroll')){
          item.scroll(item);
        }
      });
  },
  resize : function(){
    this.modules.forEach(function(item, i){
      if(item.hasOwnProperty('resize')){
        item.resize(item);
      }
    });
  }
}

josh.modules.push(require('./global'));
josh.modules.push(require('./fajax'));

josh.init(josh);
document.addEventListener('DOMContentLoaded', josh.ready.bind(josh));
document.addEventListener('scroll', josh.scroll.bind(josh));
window.addEventListener('resize', josh.resize.bind(josh));