'use strict';

var $ = require('jquery');
var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');
var VimeoPlayer = require('@vimeo/player');

var global = {
  init: function(){
  },

  ready: function(){
    this.popupImages();
    this.popup();
    if ($('.nav-icon').length){
      this.nav.init();
    } 
    if ($('.portfolio-images').length){
      this.masonryGrid.init();
    }  
    if ($('.video').length){
      this.video.init();
    }
  },
  
  resize:function(){
    
    if ($('.portfolio-images').length){
      this.popupImageResize();
      if ($(window).width() < 850){
        if(this.masonryGrid.msnry){
          this.masonryGrid.destroy();
        }
      }else{
        if ($('.portfolio-images.two-up.no-masonry').length){
          this.masonryGrid.init();  
        }
      }
    }
  },  
  scroll: function(){

  },
  masonryGrid: {
    msnry : null,
    init: function(){
      if ($('.portfolio-images.two-up').length){
        imagesLoaded($('.portfolio-images.two-up')[0], function(){
          $('.portfolio-images.two-up').removeClass('no-masonry');
            this.msnry = new Masonry( '.portfolio-images.two-up', {
              percentPosition: true,
              itemSelector: '.portfolio-item',
              columnWidth: '.portfolio-item'
          });
        });
      }
      
    },
    destroy: function(){
      this.msnry.destroy();
      $('.portfolio-images.two-up').addClass('no-masonry');
    }
  },
  video: { 
    vid : null,
    init: function(){
      var self = this;
      $('.video-wrap').click(function(){
        
        var options = {
          id: $(this).attr('data-video-id'),
          width: 1200,
          loop: false
        }; 
        self.vid = new VimeoPlayer('video', options);
        self.vid.loadVideo($(this).attr('data-video-id'));
        $('.popup').addClass('open');
        setTimeout(function(){
          self.vid.play();  
        },500);
        
      });
      $('.popup').click(function(){
        self.vid.pause();  
        $('.popup').removeClass('open');
      });
      $('.popup #video iframe').click(function(e){
        e.stopPropagation();
      });
    },
  },
  popup: function(){
    $('.popup').click(function(){
      $(this).removeClass('open');
      $('body').removeClass('overlay-open');
    });
    $('.popup img').click(function(e){
      e.stopPropagation();
    });
  },
  popupImages: function(){
    $('.portfolio-item img').click(function(){
      if($(window).width() > 850)
      {
        var img = $(this).clone();

        $('.popup .image').html(img);
        $('.popup').addClass('open');
        $('body').addClass('overlay-open');
      }
    });
  },
  popupImageResize: function(){
    if($(window).width() > $(window).height())
    {
      $('.popup .image img').height($('.popup .image').height()).width('auto');
    }
    else
    {
      $('.popup .image img').width($('.popup .image').width()).height('auto');
    }
  },
  nav: {
    init: function(){      
      $('.nav-icon').click(function() {

        if ($('body').hasClass('nav-open')){
          $('body').toggleClass('nav-open');
        }else{
          $('body').toggleClass('nav-open');
        }
      });
    }
  }

};
module.exports = global;